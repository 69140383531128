  // Teams Token Acquisition
 export const acquireAccessToken = async (token) => {
    try {
      // console.log("token:::", token);
      const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
      const response = await fetch(`${baseUrl}/api/auth/token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data= await response.json();
        sessionStorage.setItem('swiss.api.access_token', data.access_token);
        return data
      } else {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        throw new Error(`Token request failed with status: ${response.status}`);
      }
    } catch (error) {
      localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      console.error("Token fetch error: ", error);
    }
  };

  // Acquire User Profile
  export const acquireUserProfile = async (token) => {
    try {
      const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
      const response = await fetch(`${baseUrl}/api/auth/userprofile`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        let profile=await response.json();         

        profile["name"] = profile.displayName;
        profile["username"] = profile.mail;

        // console.log("User Profile: ", profile);
        sessionStorage.setItem('swiss.sso', JSON.stringify(profile));
        return profile
      } else {
        throw new Error(`User profile request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Profile fetch error: ", error);
    }
  };


  export const refreshAccessToken= async () => {
    try {
      const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
      let token= sessionStorage.getItem('swiss.api.access_token')
      const response = await fetch(`${baseUrl}/api/auth/refreshtoken`, {
        method: "POST",
        headers: { 
         "Content-Type": "application/json",
          "authorization" : `Bearer ${token}`
         },
    });
  
      if (response.ok) {
        const data= await response.json();
        sessionStorage.setItem('swiss.api.access_token', data.access_token);
        return data
      } else {
        throw new Error(`Token request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Token fetch error: ", error);
    }
  };

