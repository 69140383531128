import React from "react";
import "./Message.css";

const Message = ({ text, createdAt, from }) => (
  <div className="msg-class">
    <p className={`message ${from}`}>
      {/* <div className="icon"></div> */}
      {text} <span className="message-time">{createdAt}</span>
    </p>
  </div>
);

export default Message;
