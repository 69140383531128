const getRedisConvo =  (session, token) => {
  return new Promise(async (resolve, reject) => {
    const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
    const response = await fetch(`${baseUrl}/api/getRedisConvo`, {
        method: "POST",
        headers: {
          "authorization" : `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
        session: session
        }),
    });
    if (!response.ok) {
      reject({})
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log("Data from backend to get the conversation", data);
      resolve(data);

  })
  };

  export default getRedisConvo;