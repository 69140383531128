import React, { useState, useEffect, useCallback, useRef } from "react";
import { TextField, Button, Paper, List, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LanguageSelector from "./LanguageSelector";
import Message from "./Message";
import "./Chatbot.css";
import { ITServices } from "../usecases/Database/IT Category & Subcategory";
import Card from "./Card";
import { useMsal } from '@azure/msal-react';
import { ITServiceMapping } from "../usecases/Database/NumberMapping";
import { RequestedItemMapping } from "../usecases/Database/NumberMapping";
import { userDetails } from "../usecases/Halo/getUserDetails";
import { v4 as uuidv4 } from 'uuid';
import TKTCard from "./ticketcard";
import axios from "axios";
import { acquireAccessToken, acquireUserProfile, refreshAccessToken } from "../authUtil";
import getRedisConvo from '../utils/getRedisConvo';
import updateRedis from '../utils/updateRedis';
import config from "../utils/config";
var dbliveagent
var dbkbticket
var dbnormalticket
var dblanguage
var dbemail
var selectedKbArticle
var Lan
var transcliveagent
var translatedKBArticles
var translatedTexts
var transmailmsg
var makerequest;
var raiseaticketbutton;
var transraiseaticketbutton
var exitchat;
var searchwithtktnumber;
var showmoretktdetails;
var liveagent;
var mainmenu;
var last5tkt;
var userInfo;
var textarea;
let message;
var summary;
var iTservice;
var requestItem;
var impactedUser;
var otheruserID;
var ticketnum;
var ticketdata;
var selectedITservice;
var selectedRequestItem;
var newSessionId;
var count = 0;
var datacount = 0;
const conversationHistory = [];
var convo;
var agentgenesys
var convsdata;
let ws = null;
var menumain;
var userName
var agentName
var hasExecuted = false;
var openKBarticle = [];
var preSignedURL = [];
var submitbutton
var reviewMessage = 'Please review the Knowledge based articles and let me know if you found them helpful?';
var translatedReviewMessage;
const useStyles = makeStyles({
  messageList: {
    flexGrow: 1,
    overflowY: "auto",
  },
  inputArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  input: {
    flexGrow: 1,
    marginRight: "10px",
  },
});
var transrequestraise;

const Chatbot = () => {
  const { accounts } = useMsal();
  const [auth, setAuth] = useState(false);
  let sso = {};
  try {
    sso = JSON.parse(sessionStorage.getItem('swiss.sso'));
  } catch (err) {
    console.log("Error in SSO")
  }
  useEffect(() => {
    const initTeamsSSO = async () => {
      if (accounts[0]) {
        // console.log("accounts[0].idToken", accounts[0].idToken)
        if (!sessionStorage.getItem('swiss.api.access_token')) {
          await acquireAccessToken(accounts[0].idToken).then((value) => {
            // console.log("============================response0data1:", value);
            let profile = acquireUserProfile(value.access_token);
            setAuth(true);
          })
        } else {
          setAuth(true);
        }
      }
    }
    initTeamsSSO();
  }, [auth]);

  const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
  const user = accounts[0] || sso;
  // console.log("-------user:", user);
  //fetching token from sessionStorage

  const [menuafterticketdetails, setmenuafterticketdetails] = useState(null);
  const [buttonmenuafterticketdetails, setbuttonmenuafterticketdetails] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [inputticket, setInputticketnum] = useState("");
  const [language, setLanguage] = useState(null);
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
  const [translatedOptions, setTranslatedOptions] = useState({});
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [extraButtonsVisible, setExtraButtonsVisible] = useState(false);
  const [awaitingDescription, setAwaitingDescription] = useState(false);
  const [pendingOption, setPendingOption] = useState("");
  const [noKbButtonsVisible, setNoKbButtonsVisible] = useState(false);
  const [createIncidentsVisible, setcreateIncidentsVisible] = useState(false);
  const [createIncidentsVisibleTranslations,setcreateIncidentsVisibleTranslations] = useState({});
  const [noKbButtonTranslations, setNoKbButtonTranslations] = useState({});
  const [myTicketsExtraButtonsVisible, setMyTicketsExtraButtonsVisible] = useState(false); // State for "My Tickets" extra buttons
  const [myTicketsButtonTranslations, setMyTicketsButtonTranslations] = useState({}); // State for "My Tickets" button translations
  const [translatedAssistanceMessage, setTranslatedAssistanceMessage] = useState("");
  const [selfDropdownVisible, setSelfDropdownVisible] = useState(false);
  const [showSevenOptions, setShowSevenOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [showApplicationList, setShowApplicationList] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showMainMenuExitButtons, setShowMainMenuExitButtons] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [mainmenubutton, setmainmenu] = useState(true);
  const [exitchatbutton, setexitchat] = useState(false);
  const [backtotickets, setbacktotickets] = useState(false);
  const [searchwithtktnum, setsearchwithtktnum] = useState(false);
  const [showMoreTicketDetailsButton, setShowMoreTicketDetailsButton] = useState(false);
  const [kblink, setkblink] = useState(false);
  const [otherButtonsVisible, setOtherButtonsVisible] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketForm, setIsTicketForm] = useState(false);
  const [chatliveagent, setchatliveagent] = useState(false);
  const [kbYesNO, setkbYesNo] = useState(false);
  const [kbYesNOTranslations, setkbYesNoTranslations] = useState({});
  const [kbYesNO1, setkbYesNo1] = useState(false);
  const [kbYesNOTranslations1, setkbYesNoTranslations1] = useState({});
  const [sessionId, setSessionId] = useState(null);
  const [showKBarticleButtons, setShowKBarticleButtons] = useState(true);
  const [userSelection, setUserSelection] = useState(null);
  const [raiseRequestButtonVisible, setRaiseRequestButtonVisible] = useState(false);
  const [isinputEnabled, setIsInputEnabled] = useState(false);
  const [translatedKBArticleButtons, setTranslatedKBArticleButtons] = useState([]);
  const classes = useStyles();
  const [languageKb, setLanguageKb] = useState('en');
  const [KBhelpList, setKBhelpList] = useState([]);
  const [showKBhelpList, setShowKBhelpList] = useState(false);
  var botMessage;
  var url;
  let isConfigured = false;
  var langauge;
  const queryParams = new URLSearchParams(window.location.search);
  const isInTeams = queryParams.get("msteams") === "true";
  var token = sessionStorage.getItem('swiss.api.access_token');

  // Fetching token from session
  if (sessionStorage.getItem('swiss.api.access_token')) {
    const interval = setInterval(() => {
      refreshAccessToken();
      console.log("Refresh token call here");
    }, 900000); // 15 minutes
  }

  const messageEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages, showMoreTicketDetailsButton, showMainMenuExitButtons, otherButtonsVisible, kbYesNO, kbYesNO1, noKbButtonsVisible, createIncidentsVisible, myTicketsExtraButtonsVisible, menuafterticketdetails, tickets, buttonmenuafterticketdetails, showAdditionalButtons, showEmailInput, showKBhelpList]);

  async function formatConversation(convo) {
    let output = '';
    // Log the received input
    console.log("Inside format conversation function");
    console.log("Type of convo:", typeof convo);
    console.log("Convo:", convo);

    // Convert the JSON string to an array
    try {
      convo = JSON.parse(convo);
    } catch (error) {
      throw new TypeError("Failed to parse input string as JSON. Ensure it is a valid JSON array.");
    }

    // Check if the parsed input is an array
    if (!Array.isArray(convo)) {
      throw new TypeError("Expected input to be an array after parsing JSON.");
    }

    // Iterate through the outer array
    convo.forEach((subArray, index) => {
      // Check if each subArray is indeed an array
      if (!Array.isArray(subArray)) {
        console.error(`Element at index ${index} is not an array.`);
        throw new TypeError(`Each element of convo should be an array. Found at index ${index}`);
      }

      // Ensure each item in the subArray is an object
      subArray.forEach((item, itemIndex) => {
        if (typeof item === 'object' && item !== null) {
          for (const [role, message] of Object.entries(item)) {
            if (role === 'Bot') {
              if (Array.isArray(message)) {
                output += `Bot: ${message.join(', ')}\n`;
              } else {
                output += `Bot: ${message}\n`;
              }
            } else if (role === user.name) {
              output += `${user.name}: ${message}\n`;
            } else if (role === agentName) {
              if (Array.isArray(message)) {
                output += `${agentName}: ${message.join(', ')}\n`;
              } else {
                output += `${agentName}: ${message}\n`;
              }
            }
          }
          // Add an extra newline after each message block for separation
          output += '\n';
        } else {
          console.warn(`Item at index ${itemIndex} is not an object:`, item);
        }
      });
    });

    return output.trim();
  }

  const validateSessionId = (newSessionId) => {
    console.log('Current Session ID:', newSessionId);
    console.log('Stored Session ID:', sessionId);
    return newSessionId === sessionId;
  };

  const generateSessionId = async () => {
    const uuid = uuidv4(); // Generate a UUID v4
    // Remove dashes to get a 32-character string
    const sessionId = uuid.replace(/-/g, '');

    return sessionId;
  };

  const addDialog = async (dialog, isAgentBot = true, userName = 'User', agentName = 'Agent') => {
    if (agentgenesys) {
      console.log('Session ID is valid');
      // Use dynamic names for agent and user based on isAgentBot flag
      conversationHistory.push({ [isAgentBot ? agentName : userName]: dialog });
      // console.log('Dialog added:', { [isAgentBot ? agentName : userName]: dialog });
    } else {
      console.log('Session ID is valid');
      // Use dynamic names for bot and user based on isAgentBot flag
      conversationHistory.push({ [isAgentBot ? 'Bot' : userName]: dialog });
      // console.log('Dialog added:', { [isAgentBot ? 'Bot' : userName]: dialog });
    }
  };
  const getConversationHistory = async () => {

    return JSON.stringify(conversationHistory, null, 2);
  };

  const translateEnglishToOther = (txt, language) => {
    return new Promise(async (resolve, reject) => {
      let data = JSON.stringify({
        MSG: txt,
        code: language
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/translate?aa=bb`,
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${token}`
        },
        data: data
      };
      try {
        console.log("Language ------------001---002");
        const response = await axios.request(config)
        console.log("Response", response)
        resolve(response.data)
      } catch (e) {
        console.error("Eroor inside translateEnglishToOther:", e)
        reject({})
      }
    })
  }
  const translateOtherToEnglish = async (txt, language) => {
    let data = JSON.stringify({
      MSG: txt,
      code: language
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/translateothertoen?aa=bb`,
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      data: data
    };
    const response = await axios.request(config)
    console.log("Response in other to en", response)
    return response.data
  }

  const translateMultipleEnglishToOther = async (txt, language) => {
    return new Promise(async (resolve, reject) => {
      let data = {
        MSG: txt,
        code: language
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/translatemultiple?aa=bb`,
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${token}`
        },
        data: data
      };
      try {
        const response = await axios.request(config)
        console.log("Response mutiple", response)
        resolve(response.data)
      } catch (e) {
        reject({})
        console.error("Error0002", e)
      }
    })

  }

  const updatedb = async () => {
    let data = JSON.stringify({
      session: newSessionId,
      dbliveagent: dbliveagent,
      dbkbticket: dbkbticket,
      dbnormalticket: dbnormalticket,
      dblanguage: dblanguage,
      dbemail: dbemail,
      dbconv: conversationHistory,
      dbKBhelp: selectedKbArticle

    });
    //console.log("data so far inside update document db", data);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/updatedb?aa=bb`,
      headers: {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      return response.data
    }
    catch (error) {
      console.log("Error in update db", error)
    }
  }

  const KBhelpListClick = async () => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid in get ticket details');
    try {
      if (KBhelpList.length === 0) {
        setkbYesNo(false);
        console.log("KBhelpList::::", KBhelpList);
        const help = "Do you need further help?"
        const transhelp = await translateEnglishToOther(
          help,
          language
        );
        addDialog(help, true)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        const yesNoOptions = ["Yes", "No"];
        const translatedYesNoOptions = await translateMultipleEnglishToOther(
          yesNoOptions,
          language
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: transhelp,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
        ]);
        setkbYesNo1(true);
        setkbYesNoTranslations1(translatedYesNoOptions);
      }

      else {
        console.log("KBhelpList::::", KBhelpList.length);
        setkbYesNo(false);
        const kbMsg = "Can you please select which Knowledge Base Article helps you?"
        const kbMsgTrans = await translateEnglishToOther(
          kbMsg,
          language
        );
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: kbMsgTrans,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
        ]);
        addDialog(kbMsgTrans, true)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token);
        await updatedb();
        console.log("showing KB list which help user::");
        setShowKBhelpList(true);
      }
    } catch (err) {
      console.log("Error while showing KB list which help user")
    }
  }

  const kbclick = useCallback(
    async (option) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      try {

        if (option === "NO") {
          console.log("Inside No::")
          setShowKBhelpList(false);
          setkblink(false);
          setkbYesNo1(false);
          setkbYesNo(false);
          setShowKBarticleButtons(false);
          const nokb = "No";
          const transnokb =
            await translateEnglishToOther(
              nokb,
              language
            );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transnokb,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
          ]);
          addDialog(nokb, false, user.name)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          raiseaticketbutton = "Raise a Ticket"
          transraiseaticketbutton = await translateEnglishToOther(
            raiseaticketbutton,
            language
          )
          setNoKbButtonsVisible(true);
          console.log("Language::::", language)
          console.log("Raise a ticket translatedt text", transraiseaticketbutton)
          setkblink(false);
          setkbYesNo1(false);
          setkbYesNo(false);
        }

        else {
          setShowKBhelpList(false);
          setShowKBarticleButtons(false);
          setkblink(false);
          setkbYesNo(false);
          selectedKbArticle = option;

          console.log("KB article which help user to resolve the issue::", selectedKbArticle);
          const yeskb = option;
          const transyeskb =
            await translateEnglishToOther(
              yeskb,
              language
            );
          addDialog(yeskb, false, user.name)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transyeskb,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
          ]);
          const help = "Do you need further help?"
          const transhelp = await translateEnglishToOther(
            help,
            language
          );
          addDialog(help, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          const yesNoOptions = ["Yes", "No"];
          const translatedYesNoOptions = await translateMultipleEnglishToOther(
            yesNoOptions,
            language
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: transhelp,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
          setkbYesNo1(true);
          setkbYesNoTranslations1(translatedYesNoOptions);
        }

      } catch (error) {
        console.error("Error translating button click message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`, 
            createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
            from: "bot" },
        ]);
      }
    },
    [language]
  );

  const kbclick1 = useCallback(
    async (option) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setkbYesNo1(false);
      setkbYesNo(false);
      setkblink(false);
      try {
        switch (option) {
          case "YES1":
            const yeskb = "Yes";
            const transyeskb =
              await translateEnglishToOther(
                yeskb,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transyeskb,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            addDialog(yeskb, false, user.name)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            await handleAgent();
            break;

          case "NO1":
            const nokb = "No";
            const transnokb =
              await translateEnglishToOther(
                nokb,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transnokb,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            addDialog(nokb, false, user.name)
            addDialog(user.username, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token);
            convo = await getRedisConvo(newSessionId, token);
            convsdata = await formatConversation(convo);
            userInfo = await userDetails(user.username, token);

            const response = await fetch(`${baseUrl}/api/resolveticketcreation?aa=bb`, {
              method: "POST",
              headers: {
                "authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                summary: summary,
                note: convsdata,
                mailID: user.username,
                ClientID: userInfo.clientID,
                SiteID: userInfo.siteID,
                UserID: userInfo.userID
              }),
            });
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            console.log("Data from frontend", data);
            const message1 = "Thank you for chatting with us"
            const translatedMessage1 = await translateEnglishToOther(
              message1,
              language
            );
            addDialog(message1, true)
            addDialog(user.username, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedMessage1,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
            dbkbticket = 'Yes'
            console.log("Kbarticle ticket before updating in document db", dbkbticket)
            await updatedb();
            setShowMainMenuExitButtons(true);
        }
      } catch (error) {
        console.error("Error translating button click message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
            createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
            from: "bot" },
        ]);
      }
    },
    [language]
  );

  const getticketdetails = async () => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid in get ticket details');
    try {
      const translatedMessage = input;
      console.log("message::", translatedMessage);
      ticketnum = input;
      console.log("User selected ticket number", ticketnum);

      const isNumeric = async (input) => {
        // Check if input is of type number and is finite
        if (typeof input === 'number' && isFinite(input)) {
          return true;
        }
        // Check if input is a string and can be converted to a number
        if (typeof input === 'string' && !isNaN(input) && !isNaN(parseFloat(input))) {
          return true;
        }
        return false;
      }
      const status = await isNumeric(ticketnum);
      if (status === false) {
        count += 1;
        if (count < 3) {
          const entercorrecttktnumber = 'Please enter a correct ticket number, please make sure you enter numeric digits'
          console.log("User entered non numeric input", count)
          const translatedTextentercorrecttktnum = await translateEnglishToOther(entercorrecttktnumber, language);
          addDialog(entercorrecttktnumber, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token);
          await updatedb();
          setMessages(prevMessages => [
            ...prevMessages,
            { text: translatedTextentercorrecttktnum,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: 'bot' }
          ]);
          setAwaitingDescription(true)
          setShowAdditionalButtons(false);
        }
        else if (count === 3) {
          count = 0
          const countexhausted = 'You’ve exceeded the limit for incorrect ticket numbers. Please check and try again later. Thank you!'
          const translatedcountexhausted = await translateEnglishToOther(countexhausted, language);
          addDialog(countexhausted, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages(prevMessages => [
            ...prevMessages,
            { text: translatedcountexhausted,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: 'bot' }
          ]);
          console.log("Limit is over user entered non numeric");
          setShowMainMenuExitButtons(true)

        }
      }

      // Check if inputticket is a ticket number
      if (/^\d+$/.test(ticketnum)) {
        // If inputticket is a ticket number, fetch ticket details
        const response = await fetch(`${baseUrl}/api/ticketnum`, {
          method: "POST",
          headers: {
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ticketnumber: ticketnum }),
        });

        if (!response.ok) {
          //throw new Error("Network response was not ok");
          datacount += 1
          console.log("Network response was not ok")
          console.log("Data count so far when response is not ok", datacount)
        }

        const data = await response.json();
        console.log("Ticket API response in line 220", data);
        console.log("Ticket API response in line 220", data.ticketnum)

        if (datacount < 3 && data.ticketnum == null) {
          const entercorrecttktnumber = "Please enter a correct ticket number";
          const translatedTextentercorrecttktnum =
            await translateEnglishToOther(entercorrecttktnumber, language);
          addDialog(entercorrecttktnumber, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedTextentercorrecttktnum,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);

          console.log("Data count so far", datacount)
          setAwaitingDescription(true);
        } else if (datacount === 3) {
          datacount = 0
          const countexhausted =
            "You’ve exceeded the limit for incorrect ticket numbers. Please check and try again later. Thank you!";
          const translatedcountexhausted = await translateEnglishToOther(
            countexhausted,
            language
          );
          addDialog(countexhausted, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedcountexhausted,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
          console.log("Limit is over", datacount);
          setAwaitingDescription(false);
          setShowAdditionalButtons(false);
          setShowMainMenuExitButtons(true);
        } else {
          // Assuming the API returns a ticket details object
          const ticketDetails = [
            "Ticket Number",
            "Ticket Summary",
            "Ticket State",
          ];
          const translatedTicketDetails = await translateMultipleEnglishToOther(
            ticketDetails,
            language
          );
          console.log("Inside get ticket details functions");
          const tktresponse = `${translatedTicketDetails[ticketDetails[0]]} ${data.ticketnum} ${translatedTicketDetails[ticketDetails[1]]} ${data.summary} ${translatedTicketDetails[ticketDetails[2]]} ${data.status}`
          const engtktresponse = `Here are your ticket details: Ticket Number: ${data.ticketnum}, Ticket Summary: ${data.summary}, Ticket State: ${data.status}`;
          addDialog(engtktresponse, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          const transcardtitle = await translateEnglishToOther("Ticket Details", language)
          const cardtktdetails = {
            title: `${transcardtitle}`,
            numbertkt: `${translatedTicketDetails[ticketDetails[0]]}: ${data.ticketnum}`,
            tktsummary: `${translatedTicketDetails[ticketDetails[1]]}: ${data.summary}`,
            statetkt: `${translatedTicketDetails[ticketDetails[2]]}: ${data.status}`
          }
          setOtherButtonsVisible(false); // Hide other buttons
          setmenuafterticketdetails(cardtktdetails)
          setAwaitingDescription(false);
          setShowAdditionalButtons(false);

        }
      }
    } catch (error) {
      console.log("Feedback from API", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
          createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
          from: "bot",
        },
      ]);
    }
  };

  useEffect(async () => {
    const initialMsg = `Hello ${user.name}, Please choose your preferred language of communication.`
    const initializeSession = async () => {
      newSessionId = await generateSessionId();
      setSessionId(newSessionId);
      console.log('Generated Session IDD:', newSessionId);
      console.log("Set session id", sessionId)
    };
    await initializeSession();

    //await initializeSession();
    try {
      await addDialog(initialMsg, true);
      await getConversationHistory();
      console.log("Conversation History in code", conversationHistory)
      setMessages(
        [
          {
            text: initialMsg,
            createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
            from: 'bot',
          },
        ]);

      await updateRedis(conversationHistory, newSessionId, token)
      dbemail = user.username
      console.log("User email to update in Document DB", dbemail)
      await updatedb();
    }
    catch (error) {
      console.error('Error fetching Lex response from CF:', error);
    }
  }, []);

  const handlegetticket = async (e) => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');

    e.preventDefault();
    if (inputticket.trim() && language) {
      addDialog(inputticket, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputticket,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      setInput(false);
      setAwaitingDescription(false);
      await getticketdetails();
    }
  };

  const handlebacktotickets = useCallback(async () => {
    setmenuafterticketdetails(false)
    setbuttonmenuafterticketdetails(false)
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const back2back = "Last 5 Tickets";
      const transback2back =
        await translateEnglishToOther(
          back2back,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transback2back,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      console.log('Session ID is valid');
      message = `Please wait for a moment`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);

      console.log("token value::", token);
      // Fetch the latest tickets data
      const response = await fetch(`${baseUrl}/api/mytickets?mail=${encodeURIComponent(user.username)}`, {
        headers: {
          "authorization": `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Inside handleback to tickets", data);
      if (Array.isArray(data) && data.length > 0) {
        const moreTicketMessage = "Please find your recent tickets below. If you would like to see more tickets please"
        const transmoreTicketMessage = await translateEnglishToOther(
          moreTicketMessage,
          language
        );
        const hereclick = "click here"
        const transhereclick = await translateEnglishToOther(
          hereclick,
          language
        )
        const haloUrl = `${config.Halo_Ticket_URL}/home`;
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: (
              <span>
                {transmoreTicketMessage}{" "}
                <a href={haloUrl}target="_blank"rel="noopener noreferrer"> {transhereclick} </a>
              </span>
            ),
            createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
            from: "bot",
          },
        ]);
        addDialog(moreTicketMessage, true)
        await getConversationHistory()
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setTickets(data);
        setsearchwithtktnum(false);
        setMyTicketsExtraButtonsVisible(false);
        setShowMoreTicketDetailsButton(false);
        setOtherButtonsVisible(false);
        setbacktotickets(false);
        setexitchat(false);
        setchatliveagent(false);
        setShowAdditionalButtons(false);
        setShowMainMenuExitButtons(false);
      } else {
        setTickets([]);
        const notickets = "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.";
        const noticketstranslated = await translateEnglishToOther(
          notickets,
          language
        );
        addDialog(notickets, true)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: noticketstranslated,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
        ]);
        setShowMainMenuExitButtons(true);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      setTickets([]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "There was an error fetching tickets.",createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
    }
  }, [language]);

  const handleTicketSelection = async (ticket) => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setSelectedTicket(ticket);
      setTickets([])
      await fetchTicketDetails(ticket);
    }
    catch {
      console.log("Error in handleback to tickets function")
    }
  };
  const fetchTicketDetails = async (ticket) => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: ticket,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);

      const response = await fetch(`${baseUrl}/api/ticketnum?aa=bb`, {
        method: "POST",
        headers: {
          "authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ticketnumber: ticket }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Ticket API response::", data);
      const ticketDetails = [
        "Ticket Number",
        "Ticket Summary",
        "Ticket State",
      ];
      const translatedTicketDetails = await translateMultipleEnglishToOther(
        ticketDetails,
        language
      );
      console.log("Inside fetch ticket details functions");
      const tktdetails = `${translatedTicketDetails[ticketDetails[0]]} ${data.ticketnum
        } ${translatedTicketDetails[ticketDetails[1]]} ${data.summary} ${translatedTicketDetails[ticketDetails[2]]
        } ${data.status}`;
      const engtktresponse = `Here are your ticket details: Ticket Number: ${data.ticketnum}, Ticket Summary: ${data.summary}, Ticket State: ${data.status}`;
      addDialog(ticket, false, user.name)
      addDialog(engtktresponse, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const transcardtitle = await translateEnglishToOther("Ticket Details", language)
      const cardtktdetails = {
        title: `${transcardtitle}`,
        numbertkt: `${translatedTicketDetails[ticketDetails[0]]}: ${data.ticketnum}`,
        tktsummary: `${translatedTicketDetails[ticketDetails[1]]}: ${data.summary}`,
        statetkt: `${translatedTicketDetails[ticketDetails[2]]}: ${data.status}`
      }

      setOtherButtonsVisible(false); // Hide other buttons
      setAwaitingDescription(true);
      setmenuafterticketdetails(cardtktdetails);
      setShowAdditionalButtons(false);
      ticketnum = data.ticketnum
      console.log("Ticket number", data.ticketnum)
      console.log("ticketcardddd", menuafterticketdetails);
      console.log("Ticket number in line 1298", ticketnum);
    } catch (error) {
      console.error("Error fetching ticket details:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
          createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
          from: "bot",
        },
      ]);
    }
  };
  const handlesearchwithtktnum = async () => {
    setmenuafterticketdetails(false)
    setbuttonmenuafterticketdetails(false)
    try {
      agentgenesys = false
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const srchtktnum = "Search with Ticket Number";
      const transsrchtktnum =
        await translateEnglishToOther(
          srchtktnum,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transsrchtktnum,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      console.log('Session ID is valid');
      const tktsearch = 'Selected "Search with ticket number"'
      console.log("Entered handlesearch with ticket number", textarea);
      const enterticketnumber = "Please enter your ticket number";
      const enterticketnumbertranslated = await translateEnglishToOther(
        enterticketnumber,
        language
      );
      addDialog(tktsearch, false, user.name)
      addDialog(enterticketnumber, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: enterticketnumbertranslated, createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      console.log("count in handle search with tkt num", count)
      setShowMoreTicketDetailsButton(false);
      setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      setOtherButtonsVisible(false); // Hide other buttons
      setexitchat(false);
      setchatliveagent(false);
      setbacktotickets(false);
      setsearchwithtktnum(false);
      setTickets(false);
      setAwaitingDescription(true);
    }
    catch {
      console.log("Error in handlesearchwithtktnum")
    }
  };
  const handleShowMoreTicketDetails = async () => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    setmenuafterticketdetails(null)
    console.log('Session ID is valid');
    const showmore = "Show More Ticket Details"
    const transshowmore =
      await translateEnglishToOther(
        showmore,
        language
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transshowmore,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
    ]);
    const moredetails = 'Selected "Show more ticket details"'
    addDialog(moredetails, false, user.name)
    await getConversationHistory();
    await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    console.log("User selected ticket number in 349 line", ticketnum);
    window.open(
      `${config.Halo_Ticket_URL}/ticket?id=${ticketnum}`,
      "_blank"
    );
    setbuttonmenuafterticketdetails(true)
  };

  const showPDFInNewTab = (url) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('swiss.api.access_token')}`,
        'Content-Type': 'application/pdf'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); // Get the response as a Blob
      })
      .then(blob => {
        const url = URL.createObjectURL(blob); // Create a URL for the Blob
        window.open(url, '_blank'); // Open the Blob URL in a new tab
      })
      .catch(error => {
        console.error('Error fetching PDF:', error);
      });
  }

  const kbLink = async (url, name) => {
    console.log("language kb", languageKb);
    url = `${baseUrl}/api/getKbArticle?language=${languageKb}&fileName=${name}&token=${sessionStorage.getItem('swiss.api.access_token')}`
    window.open(`${url}`, "_blank"); // Open the KB article in a new tab
    openKBarticle.push(name); // Track opened articles
    const transqueryraise =
      await translateEnglishToOther(
        name,
        languageKb
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transqueryraise,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
    ]);
    addDialog(name, false, user.name); // Add user's dialog
    console.log("opened KB", openKBarticle);
    setKBhelpList((prevArticles) => [...prevArticles, transqueryraise]);
    // Prepare the follow-up message
    const message = "Please review the Knowledge based articles above and let me know if you found them helpful?";
    addDialog(message, true);
    // Update conversation history
    await getConversationHistory();
    await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    //Translate and send the bot's message
    const translatedMessage = await translateEnglishToOther(message, language);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedMessage, createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
    ]);
    //Prepare Yes/No options
    const noKbOptions = ["Yes", "No"];
    const translatedNoKbTexts = await translateMultipleEnglishToOther(noKbOptions, language);
    setkbYesNoTranslations(translatedNoKbTexts);
  };

  const handleexit = async () => {
    try {
      setmenuafterticketdetails(null)
      setbuttonmenuafterticketdetails(false)
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setmenuafterticketdetails(false)
      console.log('Session ID is valid');
      const chatend = "End Chat";
      const transchatend =
        await translateEnglishToOther(
          chatend,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transchatend,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      const chatexit = 'Selected "End chat"'
      const exitchatmsg = "Thank you for chatting. Have nice day!";
      console.log("language inside handle exit", language);
      const translatedTextexitchatmsg = await translateEnglishToOther(
        exitchatmsg,
        language
      );
      addDialog(chatexit, false, user.name)
      addDialog(exitchatmsg, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedTextexitchatmsg,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      setShowMoreTicketDetailsButton(false);
      setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      setOtherButtonsVisible(false); // Hide other buttons
      setexitchat(false);
      setchatliveagent(false);
      setbacktotickets(false);
      setsearchwithtktnum(false);
      setTickets(false);
      setAwaitingDescription(false)
    }
    catch {
      console.log("Error in handleexit function")
    }
  };

  const handleagentuser = async (input) => {
    let modify1;
    console.log("Ws in handle agent start:::", ws)
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    console.log("Input Inside handleagentuser", input); setInput('')
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: input,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
    ]);
    setInput('');
    try {
      const sendMessageToGenesys = async (message, tokenGenesys) => {
        console.log("Ws in handle agent user :::", ws)
        return new Promise((resolve, reject) => {
          if (!ws) {
            reject(new Error('WebSocket connection not established'));
            return;
          }
          if (message && message.text) {
            const onMessagePayload = JSON.stringify({
              action: 'onMessage',
              token: tokenGenesys,
              message: message
            });
            ws.send(onMessagePayload);
            console.log('Message sent to Genesys in handle agent user :', onMessagePayload);
          } else {
            reject(new Error('Message payload is missing'));
          }
          ws.onmessage = async (event) => {
            let parsedData;
            try {
              parsedData = JSON.parse(event.data);
            } catch (err) {
              console.error('Error parsing JSON:', err);
              return;
            }
            console.log('Received message from Genesys in handleagentuser', parsedData);
            console.log('Received message from Genesys in handleagentuser with out parsingggggggggg', event.data);
            var agentName = parsedData.body.channel.from.nickname
            var typeconv = parsedData.body.type
            var fromGenesys = parsedData.body.text;
            async function handleMessage(parsedData, language, userName) {
              if (typeconv === 'Event' && !hasExecuted) {
                // Set the flag to true to prevent re-execution
                hasExecuted = true;
                console.log("inside if of handleMessage")
                console.log("Agent name in Genesys", agentName);
                var greeting = "You are now connected with";
                const translatedGreeting = await translateEnglishToOther(greeting, language);
                var greetingAndAgentName = `You are now connected with ${agentName}`;
                var translatedGreetingAndAgentName = `${translatedGreeting} ${agentName}`;
                addDialog(greetingAndAgentName, true, userName, agentName);
                await getConversationHistory();
                await updateRedis(conversationHistory, newSessionId, token)
                await updatedb();
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: translatedGreetingAndAgentName,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                ]);
                console.log("Message from Genesys after call reached to agent inside handle message function", fromGenesys);
                const translatedFromGenesys = await translateEnglishToOther(fromGenesys, language);
                addDialog(fromGenesys, true, userName, agentName);
                await getConversationHistory();
                await updateRedis(conversationHistory, newSessionId, token)
                await updatedb();
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: translatedFromGenesys,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                ]);
              }
            }
            console.log("Langauge before calling handle message function in handke agent user", language)
            await handleMessage(parsedData, language, userName)
            if (parsedData.body.type == 'Text') {
              console.log("Type::::", parsedData.body.type)
              // Check the structure of the message to ensure it contains the desired data
              if (parsedData.type === 'message' && parsedData.class === 'StructuredMessage') {
                const body = parsedData.body;
                if (body.direction === 'Outbound') {
                  // Extract the text
                  const text = body.text;
                  console.log('Modified message from Genesys:', text);
                  // Store the text in a variable
                  modify1 = text;
                  const translatedgenesysinput = await translateEnglishToOther(
                    modify1,
                    language
                  );
                  console.log("Agent name in Genesys in handleagentUserrr", agentName)
                  addDialog(modify1, true, userName, agentName)
                  await getConversationHistory();
                  await updateRedis(conversationHistory, newSessionId, token)
                  await updatedb();
                  console.log("text::", modify1)
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: translatedgenesysinput,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
                  ]);
                }
              }
            }
            const events = parsedData.body.events;
            // Finding the "Disconnect" event
            const disconnectEvent = events.find(event => event.presence && event.presence.type === "Disconnect");
            if (disconnectEvent) {
              console.log("Disconnect event found:", disconnectEvent);
              //else if(parsedData.body.events.presence.type == "Disconnect"){
              var agentName = parsedData.body.channel.from.nickname
              console.log("Agent name in Genesys in handleagentUser inside if of disconnect", agentName)
              var disconnectmsg = "Your conversation is closed by live agent. Have nice day!!"
              addDialog(disconnectmsg, true, userName, agentName)
              agentgenesys = false
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              const translateddisconnectmsg = await translateEnglishToOther(
                disconnectmsg,
                language
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translateddisconnectmsg, createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),from: "bot" },
              ]);
              setAwaitingDescription(false)
              setShowMainMenuExitButtons(true)
            } else {
              console.log("No disconnect event found.");
            }
            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.class === 'StructuredMessage') {
                if (parsedData.body.direction === 'Inbound') {
                  console.log('Received inbound message from Genesys in  handle agent user:', parsedData.body.text);
                  resolve(parsedData.body.text);
                } else {
                  console.log('Received outbound message or other types of message in handle agent user inside structured message', parsedData);
                }
              } else {
                console.log('Received other message:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing message:', err);
              reject(err);
            }
          };
          ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);
          };
          setTimeout(() => {
            reject(new Error('Timeout: No response from Genesys'));
          }, 10000); // 10 seconds timeout
        });
      };

      const runWebSocket = async () => {
        const url = `wss://webmessaging.aps1.pure.cloud/v1?deploymentId=${config.Genesys_Deployment_ID}`;
        const tokenGenesys = newSessionId; // Replace with your token
        try {
          const translatedusermessage = await translateOtherToEnglish(
            input,
            language
          );
          addDialog(translatedusermessage, false, user.name, agentName)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token);
          console.log("Language before passing the call to Genesys in handle agent user", Lan)
          console.log("User's name before passing the call to Genesys in handle agent user ", user.name)
          console.log("User's mail before passing the call to Genesys in handle agent user", user.username)
          const message = {
            type: 'Text',
            text: translatedusermessage,
            "channel": {
              "metadata": {
                "customAttributes": {
                  "language": Lan,
                  "username": user.name,
                  "useremail": user.username,
                  "intent": ""
                }
              }
            }
          }
          const response = await sendMessageToGenesys(message, tokenGenesys);
          dbliveagent = 'Yes'
          console.log("Live agent before updating in document db inside handleagent user", dbliveagent)
          await updatedb();
          console.log('Message from Genesys:', response);
        } catch (error) {
          console.error('Error during WebSocket interaction:', error);
        }
      };
      await runWebSocket()
    }
    catch (error) {
      console.log("Error in handle agent user function", error)
    }
  }
  const handleAgent = async () => {
    try {
      setShowMoreTicketDetailsButton(false);
      setShowMainMenuExitButtons(false); // Show the "Show more ticket details" button
      setOtherButtonsVisible(false); // Hide other buttons
      setexitchat(false);
      setchatliveagent(false);
      setbacktotickets(false);
      setsearchwithtktnum(false);
      setTickets(false);
      setAwaitingDescription(true)
      setExtraButtonsVisible(false)
      setNoKbButtonsVisible(false)
      setcreateIncidentsVisible(false)
      setSelfDropdownVisible(false)
      setShowSevenOptions(false)
      setSelectedOption(false)
      setSelectedCard(false)
      setShowAdditionalButtons(false);
      setShowSubcategories(false);
      setmenuafterticketdetails(null)
      setbuttonmenuafterticketdetails(false)
      var fromGenesys;
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const agenthandle = "Chat with live agent ";
      const transagenthandle =
        await translateEnglishToOther(
          agenthandle,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transagenthandle,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      console.log('Session ID is valid');
      const checkagent = 'Selected "Live Agent"'
      setSelectedCard(null);
      const liveagenttext = "Please wait while I connect you with a live agent. Your chat will be answered in 30 sec";
      const translatedliveagent = await translateEnglishToOther(
        liveagenttext,
        language
      );
      addDialog(checkagent, false, user.name)
      addDialog(liveagenttext, true)
      agentgenesys = true
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      convo = await getRedisConvo(newSessionId, token);
      convsdata = await formatConversation(convo);
      console.log("Formated conversation", convsdata);
      console.log("type of convs data in live agent", typeof convsdata)
      const configureSession = async (url, tokenGenesys, deploymentId) => {
        return new Promise((resolve, reject) => {
          if (ws) {
            console.log('WebSocket already established');
            resolve();
            return;
          }
          ws = new WebSocket(url);
          console.log("Ws in handle agent:::", ws)
          ws.onopen = () => {
            console.log('WebSocket connection established');
            const configureSessionPayload = JSON.stringify({
              action: 'configureSession',
              token: tokenGenesys,
              deploymentId: deploymentId
            });
            ws.send(configureSessionPayload);
            console.log('Session configuration message sent:', configureSessionPayload);
          };
          ws.onmessage = (event) => {
            console.log('Received message during session configuration:', event.data);
            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.type === 'response' && parsedData.class === 'SessionResponse' && parsedData.code === 200) {
                console.log('Session successfully configured:', parsedData);
                resolve();
              } else {
                console.log('Unexpected response during session configuration:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing session configuration response:', err);
              reject(err);
            }
          };
          ws.onerror = (error) => {
            console.error('WebSocket error during session configuration:', error);
            reject(error);
          };
          ws.onclose = async () => {
            ws = null;
            console.log('WebSocket connection closed');
            const closestatment = "As we did not hear back from you, we are closing this conversation. Have a nice day!"
            const translatedclosestatment = await translateEnglishToOther(
              closestatment,
              language
            );
            addDialog(closestatment, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedclosestatment,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
          };

          setTimeout(() => {
            reject(new Error('Timeout: No response from Genesys'));
          }, 10000); // 10 seconds timeout
        });
      };
      // Sending the conversation BOT and USER had so far to
      const sendMessageToGenesys = (message, tokenGenesys) => {
        return new Promise((resolve, reject) => {
          if (!ws) {
            reject(new Error('WebSocket connection not established'));
            return;
          }
          if (message && message.text) {
            const onMessagePayload = JSON.stringify({
              action: 'onMessage',
              token: tokenGenesys,
              message: message
            });
            ws.send(onMessagePayload);
            console.log('Message sent to Genesys:', onMessagePayload);
          } else {
            reject(new Error('Message payload is missing'));
          }
          ws.onmessage = async (event) => {
            console.log('Received message from Genesys in handle agent:', event.data);
            try {
              const parsedData = JSON.parse(event.data);
              if (parsedData.class === 'StructuredMessage') {
                if (parsedData.body.direction === 'Inbound') {
                  console.log('Received inbound message from Genesys:', parsedData.body.text);
                  resolve(parsedData.body.text);
                } else {
                  console.log('Received outbound message from genesys with outbound direction', parsedData);
                  console.log("Received outbound message from genesys with outbound direction with out parse", event.data)
                  var typeconv = parsedData.body.type
                  var agentName = parsedData.body.channel.from.nickname;
                  fromGenesys = parsedData.body.text;
                  console.log("Conversation type", typeconv)
                  console.log(" meesage typed by agent", fromGenesys)
                  const events = parsedData.body.events;
                  if (parsedData && parsedData.body && Array.isArray(parsedData.body.events)) {
                    const disconnectEvent = events.find(event => event.presence && event.presence.type === "Disconnect");
                    console.log("Disconect::", disconnectEvent)
                    if (disconnectEvent) {
                      console.log("Disconnect event found:", disconnectEvent);
                      //else if(parsedData.body.events.presence.type == "Disconnect"){
                      var agentName = parsedData.body.channel.from.nickname
                      console.log("Agent name in Genesys in handleagentUser inside if of disconnect", agentName)
                      var disconnectmsg = "Your conversation is closed by live agent. Have nice day!!"
                      agentgenesys = false
                      addDialog(disconnectmsg, true, userName, agentName)
                      await getConversationHistory();
                      await updateRedis(conversationHistory, newSessionId, token)
                      await updatedb();
                      const translateddisconnectmsg = await translateEnglishToOther(
                        disconnectmsg,
                        language
                      );
                      setMessages((prevMessages) => [
                        ...prevMessages,
                        { text: translateddisconnectmsg,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
                      ]);
                      setAwaitingDescription(false)
                      setShowMainMenuExitButtons(true)
                    }
                    else {
                      async function handleMessage(parsedData, language, userName) {
                        if (typeconv === 'Event' && !hasExecuted) {
                          // Set the flag to true to prevent re-execution
                          hasExecuted = true;
                          console.log("inside if of handleMessage")
                          console.log("Agent name in Genesys", agentName);
                          var greeting = "You are now connected with";
                          console.log("Langauge in handle agent function", language)
                          const translatedGreeting = await translateEnglishToOther(greeting, language);
                          var greetingAndAgentName = `You are now connected with ${agentName}`;
                          var translatedGreetingAndAgentName = `${translatedGreeting} ${agentName}`;
                          addDialog(greetingAndAgentName, true, userName, agentName);
                          await getConversationHistory();
                          await updateRedis(conversationHistory, newSessionId, token)
                          await updatedb();
                          setMessages((prevMessages) => [
                            ...prevMessages,
                            { text: translatedGreetingAndAgentName,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                          ]);
                          console.log("Message from Genesys after call reached to agent inside handle message function", fromGenesys);
                          console.log("Langauge in handle agent  above from genesys line inside handle message function", language)
                          if (!fromGenesys) {
                            console.log("From Genesys is undefind", fromGenesys)
                            const translatedFromGenesys = await translateEnglishToOther(fromGenesys, language);
                            addDialog(fromGenesys, true, userName, agentName);
                            await getConversationHistory();
                            await updateRedis(conversationHistory, newSessionId, token)
                            await updatedb();
                            setMessages((prevMessages) => [
                              ...prevMessages,
                              { text: translatedFromGenesys,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                            ]);
                          }
                        }
                        else {
                          fromGenesys = parsedData.body.text
                          console.log("Message from Gensys after call reached to agent inside handle message function insdie else if", fromGenesys)
                          console.log("Langauge in handle agent  above from genesys line", language)
                          if (!fromGenesys) {
                            const translatedfromgenesys = await translateEnglishToOther(
                              fromGenesys,
                              language
                            );
                            addDialog(fromGenesys, true, userName, agentName)
                            await getConversationHistory();
                            await updateRedis(conversationHistory, newSessionId, token)
                            await updatedb();
                            setMessages((prevMessages) => [
                              ...prevMessages,
                              { text: translatedfromgenesys,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                            ]);
                          }
                        }
                      }
                      console.log("Langauge before calling handle message function in handke agent", language)
                      await handleMessage(parsedData, language, userName)
                      fromGenesys = parsedData.body.text
                      console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
                      console.log("Langauge in handle agent  above from genesys line", language)
                      const translatedfromGenesys = await translateEnglishToOther(
                        fromGenesys,
                        language
                      );
                      addDialog(fromGenesys, true, userName, agentName)
                      await getConversationHistory();
                      await updateRedis(conversationHistory, newSessionId, token)
                      await updatedb();
                      setMessages((prevMessages) => [
                        ...prevMessages,
                        { text: translatedfromGenesys,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                      ]);
                    }
                  }
                  else {
                    console.log("else 1812", fromGenesys)
                    fromGenesys = parsedData.body.text
                    console.log("Message from Gensys after call reached to agent:::::", fromGenesys)
                    console.log("Langauge in handle agent  above from genesys line", language)
                    const translatedfromGenesys = await translateEnglishToOther(
                      fromGenesys,
                      language
                    );
                    addDialog(fromGenesys, true, userName, agentName)
                    await getConversationHistory();
                    await updateRedis(conversationHistory, newSessionId, token)
                    await updatedb();
                    setMessages((prevMessages) => [
                      ...prevMessages,
                      { text: translatedfromGenesys,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "Bot" },
                    ]);
                  }
                }
              } else {
                console.log('Received other message:', parsedData);
              }
            } catch (err) {
              console.error('Error parsing message:', err);
              reject(err);
            }
          };
          ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            reject(error);
          };
          setTimeout(() => {
            reject(new Error('Timeout: No response from Genesys'));
          }, 10000); // 10 seconds timeout
        });
      };
      const runWebSocket = async () => {
        const url = `wss://webmessaging.aps1.pure.cloud/v1?deploymentId=${config.Genesys_Deployment_ID}`;
        const tokenGenesys = newSessionId; // Replace with your token
        const deploymentId = config.Genesys_Deployment_ID; // Replace with your deployment ID
        try {
          // Configure the session once
          await configureSession(url, tokenGenesys, deploymentId);
          console.log("Language before passing the call to Genesys in handle agent", Lan)
          console.log("User's name before passing the call to Genesys in handle agent ", user.name)
          console.log("User's mail before passing the call to Genesys in handle agent", user.username)
          // Send a message after the session is configured
          const message = {
            type: 'Text', text: convsdata,
            "channel": {
              "metadata": {
                "customAttributes": {
                  "language": Lan,
                  "username": user.name,
                  "useremail": user.username,
                  "intent": ""
                }
              }
            }
          }; // Replace with your message
          const response = await sendMessageToGenesys(message, tokenGenesys);
          dbliveagent = 'Yes'
          console.log("Live agent before updating in document db", dbliveagent)
          await updatedb();
          console.log('Message from Genesys:', response);
          // setAwaitingDescription(true)
        } catch (error) {
          console.error('Error during WebSocket interaction:', error);
        }
      };
      await runWebSocket();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedliveagent,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
    }
    catch (error) {
      console.error("error in handleAgent function", error)
    }
  };

  const handleSelectLanguage = async (selectedLanguage) => {
    new Promise(async (resolve, reject) => {
      console.log("handleSelectLanguage    selectedLanguage:", selectedLanguage)
      try {
        console.log("Language", selectedLanguage);
        langauge = selectedLanguage || 'en';
        setLanguageKb(langauge)
        if (menumain !== true) {
          console.log("Selected langauge", selectedLanguage)
          console.log("Language", language)
          const welcomeMessage = "I am delighted to assist you with...";
          const translatedText = await translateEnglishToOther(welcomeMessage, selectedLanguage);
          setLanguage(selectedLanguage);
          const languageslist = {
            'en': 'English',
            'es': 'Spanish',
            'fr': 'French',
            'de': 'German',
            'it': 'Italian',
            'pt': 'Brazilian Portuguese',
            'ja': 'Japanese',
            'ko': 'Korean'
          };
          Lan = `${languageslist[selectedLanguage]}`
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: Lan,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
          ]);
          dblanguage = Lan;
          console.log("Language before updating in document db", dblanguage)
          await updatedb();
          if (!validateSessionId(newSessionId)) {
            console.error('Session ID is invalid. Action not allowed.');
            console.log("Session id", newSessionId)
            return;
          }
          console.log('Session ID is valid');
          console.log("username in handle select", user.name)
          await addDialog(Lan, false, user.name)
          await addDialog(welcomeMessage, true);
          await getConversationHistory()
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedText,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
          const requestraise = "Make a Request"
          transrequestraise = await translateEnglishToOther(requestraise, selectedLanguage)

          const options = ["My Tickets", "Report an Issue", "Change Language", "Make a Request"];
          translatedTexts = await translateMultipleEnglishToOther(
            options,
            selectedLanguage
          );

          console.log("1stttt", translatedTexts["My Tickets"])
          setTranslatedOptions(translatedTexts);
          setShowAdditionalButtons(true);
          setAwaitingDescription(false)
        }
        if (menumain == true) {
          setShowMainMenuExitButtons(false)
          setSelectedCard(false)
          setmenuafterticketdetails(false)
          setSelfDropdownVisible(false)
          setMyTicketsExtraButtonsVisible(false)
          setkbYesNo(false)
          setkbYesNo1(false)
          setNoKbButtonsVisible(false)
          setcreateIncidentsVisible(false)
          setAwaitingDescription(false)
          setTickets("")
          setShowKBarticleButtons(false)
          setShowSevenOptions(false)
          setShowSubcategories(false)
          setbuttonmenuafterticketdetails(false)
          datacount = 0;
          count = 0;
          console.log("Data count so far when user selected mainmenu", datacount)
          console.log("count so far when user selected mainmenu", count)
          console.log("Inside if in handle select language - SetLangauge variable", selectedLanguage)
          console.log("Inside if in handle select language - Langauge variable", language)
          selectedLanguage = selectedLanguage ? selectedLanguage : language
          const welcomeMessage =
            "I'm delighted to assist you with...";
          const translatedText = await translateEnglishToOther(
            welcomeMessage,
            selectedLanguage
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedText,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
          setShowAdditionalButtons(true);
          await addDialog(welcomeMessage, true);
          await getConversationHistory()
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setLanguage(selectedLanguage);
          const requestraise = "Make a Request"
          transrequestraise = await translateEnglishToOther(requestraise, selectedLanguage)
          const options = ["My Tickets", "Report an Issue", "Change Language", "Make a Request"];
          translatedTexts = await translateMultipleEnglishToOther(
            options,
            selectedLanguage
          );
          console.log("Translated texts", translatedTexts)
          console.log("1stttt", translatedTexts["My Tickets"])
          setTranslatedOptions(translatedTexts);
          setShowAdditionalButtons(true);
          setAwaitingDescription(false)
          resolve(true)
        }
      } catch (error) {
        reject(false)
        console.error("Error translating options:", error);
      }
    })
  };

  const toggleSelfDropdown = () => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      console.log("Toggling dropdown visibility");
      setSelfDropdownVisible(!selfDropdownVisible);
    }
    catch {
      console.log("Erro in toggleSelfDropdown")
    }
  };

  const handleMainMenuClick = useCallback(async () => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      console.log("User clicked Main menuuuuu")
      const menumainclick = "Main Menu";
      const transmenumainclick =
        await translateEnglishToOther(
          menumainclick,
          language
        );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: transmenumainclick,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      menumain = true
      var mainmenudialog = 'Selected "Main Menu"'
      await handleSelectLanguage(language);
      // Logic for Main Menu button
      addDialog(mainmenudialog, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setShowMainMenuExitButtons(false);
      setexitchat(false);
      setbacktotickets(false);
      setsearchwithtktnum(false)
      setShowMoreTicketDetailsButton(false)
      setchatliveagent(false)
      setTickets(false)
      setShowAdditionalButtons(true);
    }
    catch {
      console.log("Error in handleMainMenuClick")
    }
  }, [language]);

  const handleExitClick = async () => {
    try {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      // Logic for Exit button
      const exit = "Thanks for chatting. Have nice day!";
      const exittranslated = await translateEnglishToOther(exit, language);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: exittranslated,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      setExtraButtonsVisible(false);
      setShowMainMenuExitButtons(true);
    }
    catch {
      console.log(" Error in handleexitclick function")
    }
  };

  const handleDropdownOptionClick = useCallback(
    async (option) => {
      try {
        if (!validateSessionId(newSessionId)) {
          console.error('Session ID is invalid. Action not allowed.');
          return;
        }
        setSelfDropdownVisible(false);
        console.log('Session ID is valid');
        addDialog(option, false, user.name)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: option, createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
        ]);
        // Add the selected option to the chat window messages
        impactedUser = option;
        message = `Please select the Relevant IT service from the drop down`;
        const translatedMessage = await translateEnglishToOther(
          message,
          language
        );
        //setchatliveagent(true)
        addDialog(message, true)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
        ]);
        // Optionally, you can close the dropdown after selecting an option
        setSelectedOption(option);
        // setSelfDropdownVisible(false);
        setShowSevenOptions(true);
      }
      catch {
        console.log("Error in handleDropdownOptionClick function")
      }
    },
    [language]
  );

  async function handleOtherButtonClick() {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    setcreateIncidentsVisible(false)
    const other = 'Selected "Others"';
    const buttonother = "Others";
    const transbuttonother = await translateEnglishToOther(
      buttonother,
      language
    );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transbuttonother,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
    ]);

    let mailmsg =
      "Please enter your mail address";
    transmailmsg = await translateEnglishToOther(
      mailmsg,
      language
    );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transmailmsg,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
    ]);
    addDialog(other, false, user.name)
    await getConversationHistory();
    await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    console.log('Session ID is valid');
    setShowEmailInput(true);
    setcreateIncidentsVisible(false)
  }

  function handleEmailChange(event) {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    setUserEmail(event.target.value);
  }

  async function handleEmailSubmit() {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    if (validateEmail(userEmail)) {
      otheruserID = userEmail;
      addDialog(userEmail, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setShowEmailInput(false);
      setcreateIncidentsVisible(false);
      setAwaitingDescription(false)
      message = `Please wait for a moment`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      addDialog(userEmail, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token);;
      userInfo = await userDetails(userEmail, token);
      console.log("Details", userInfo);
      if (userInfo[1] === "Incorrect") {
        if (userInfo[0] === 3) {
          let exhaustedmsg =
            "You’ve exceeded the limit for invalid email entries. Please check and try again later. Thank you!";
          const translatedMessagesub = await translateEnglishToOther(
            exhaustedmsg,
            language
          );
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedMessagesub,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
          addDialog(exhaustedmsg, true)
          setShowMainMenuExitButtons(true);
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
        } else {
          let exhaustedmsg = "Please enter correct email address.";
          const translatedMessagesub = await translateEnglishToOther(
            exhaustedmsg,
            language
          );
          setShowEmailInput(true);
          addDialog(exhaustedmsg, true)
          await getConversationHistory();
          await updateRedis(conversationHistory, newSessionId, token)
          await updatedb();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: translatedMessagesub,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
          ]);
        }
      } else {
        setShowEmailInput(false);
        setcreateIncidentsVisible(false);
        toggleSelfDropdown(); // Toggle the dropdown visibility
        message = "How many users are impacted, select from the drop down";
        const translatedmessage = await translateEnglishToOther(
          message,
          language
        );
        addDialog(message, true)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: translatedmessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
        ]);
      }
      // Continue with the next steps
    } else {
      const incorrectemail = "Please enter a valid email ID."
      addDialog(incorrectemail, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: incorrectemail,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
    }
  }

  const validateEmail = (email) => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    const maile = email;
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: maile, createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),from: "user" },
    ]);
    console.log('Session ID is valid');
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSevenOptionClick = useCallback(
    async (service) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setShowSevenOptions(false);
      setShowApplicationList(false);
      setShowAdditionalButtons(false);
      console.log('Session ID is valid');
      const serviceseven = service;
      addDialog(service, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: service,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      message = `Please select the Relevant Request Item from the drop down`;
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      selectedITservice = service;
      iTservice = ITServiceMapping[service];
      console.log("IT service number mapping", iTservice);
      setSelectedService(service);
      setSubcategories(ITServices[service]);
      setShowSubcategories(true);
    },
    [language]
  );

  const handleSubcategoryClick = useCallback(
    async (subcategory) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      addDialog(subcategory, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      console.log('Session ID is valid');
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: subcategory,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
      ]);
      selectedRequestItem = subcategory;
      requestItem = RequestedItemMapping[selectedITservice][selectedRequestItem];
      console.log("Request Item number mapping", requestItem);
      setShowSubcategories(false);
      console.log("Selected Subcategory:", subcategory);
      message = `Please find the details of your Ticket`;
      const translatedMessagesub = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      const detailsticket = {
        title: "Ticket Description:",
        itService: `1. IT Services : ${selectedITservice}`,
        requestItem: `2. Service Request: ${selectedRequestItem}`,
        summary: `3. Summary : ${summary}`,
        impactedUser: `4. Impacted User: ${impactedUser}`,
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      setSelectedCard(detailsticket);
      setShowAdditionalButtons(false);
    },
    [language]
  );

  const handleChatWithAgent = async () => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    const agentlive = "Connecting with Live Agent"
    console.log("Inside Handle chat with agent");
    addDialog(agentlive, true)
    await getConversationHistory();
    await updateRedis(conversationHistory, newSessionId, token)
    await updatedb();
    convo = await getRedisConvo(newSessionId, token);
    setSelectedCard(null);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: agentlive,
        createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
        from: "user",
      },
    ]);
    // Add your logic to handle live agent chat initiation
  };

  const handleSubmitTicket = useCallback(async () => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    setSelectedCard(null);
    const tktsubmit = "Submit";
    const transtktsubmit =
      await translateEnglishToOther(
        tktsubmit,
        language
      );
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: transtktsubmit,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
    ]);
    message = `Please wait for a moment`;
    const translatedMessage = await translateEnglishToOther(message, language);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
    ]);

    const response = await fetch(`${baseUrl}/api/ticketcreation?aa=bb`, {
      method: "POST",
      headers: {
        "authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        summary: summary,
        ITservice: iTservice,
        RequestItem: requestItem,
        IampactedUser: impactedUser,
        OtherMailID: userInfo.emailID,
        ClientID: userInfo.clientID,
        SiteID: userInfo.siteID,
        UserID: userInfo.userID,
      }),
    });
    if (!response.ok) {
      let exhaustedmsg = "Unfortunately unable to process the query at this moment. Please try after sometime.";
      const translatedMessagesub = await translateEnglishToOther(
        exhaustedmsg,
        language
      );
      addDialog(exhaustedmsg, true)
      await getConversationHistory()
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      throw new Error("Network response was not ok");
    }
    else {
      const data = await response.json();
      console.log("Data from frontend", data);
      let exhaustedmsg = `I have created a Ticket with Ticket Number: ${data}`;
      const translatedMessagesub = await translateEnglishToOther(
        exhaustedmsg,
        language
      );
      const userselection = 'Selected "Submit Ticket"';
      addDialog(userselection, false, user.name)
      addDialog(exhaustedmsg, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessagesub,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      dbnormalticket = 'Yes'
      console.log("Created ticket before updating in document db", dbnormalticket)
      await updatedb();
      setShowMainMenuExitButtons(true);
      setShowMainMenuExitButtons(true)
      // Add your logic to handle submission
    }
  }, [language]);

  const handleSelfButton = useCallback(
    async (action) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      setcreateIncidentsVisible(false)
      console.log('Session ID is valid');
      message = `Please wait for a moment`;
      const self = 'Selected "Myself"'
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(self, false, user.name)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const translatedmessageuser = await translateEnglishToOther(
        action,
        language
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedmessageuser,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
        { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      userInfo = await userDetails(user.username, token);
      console.log("Details", userInfo);
      setcreateIncidentsVisible(false);
      message = "How many users are impacted, select from the drop down";
      const translatedmessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedmessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" }
      ]);
      toggleSelfDropdown();
    },
    [language]
  );

  const handleButtonClick = useCallback(
    async (option) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      const myTicketsOptions = ["Last 5 Tickets", "Search with Ticket Number"];
      const translatedMyTicketsOptions =
        await translateMultipleEnglishToOther(myTicketsOptions, language);
      const myTicketsOptions1 = [
        "Last 5 Tickets",
        "Search with ticket number",
        "Show More Ticket Details",
        "Chat with live agent",
        "Main Menu",
        "End Chat",
        "Submit",
        "Send"
      ];
      var translatedtextsubmenu = await translateMultipleEnglishToOther(
        myTicketsOptions1,
        language
      );
      last5tkt = translatedtextsubmenu["Last 5 Tickets"];
      searchwithtktnumber = translatedtextsubmenu["Search with ticket number"];
      showmoretktdetails = translatedtextsubmenu["Show More Ticket Details"];
      liveagent = translatedtextsubmenu["Chat with live agent"];
      mainmenu = translatedtextsubmenu["Main Menu"];
      exitchat = translatedtextsubmenu["End Chat"];
      submitbutton = translatedtextsubmenu["Submit"]
      console.log('Session ID is valid inside handle button click');
      try {
        switch (option) {
          case "Report an Issue":
            setShowAdditionalButtons(false);
            textarea = "Report an Issue";
            const queryraise = "Report an Issue";
            const transqueryraise =
              await translateEnglishToOther(
                queryraise,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transqueryraise,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            const normalmsg = "Kindly provide a description for the issue you encountered."
            const translatedMessage = await translateEnglishToOther(
              normalmsg,
              language
            );
            addDialog(queryraise, false, user.name)
            addDialog(normalmsg, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
            setButtonsVisible(false);
            setAwaitingDescription(true);
            setRaiseRequestButtonVisible(false);
            setPendingOption(option);
            break;

          case "My Tickets":
            setShowAdditionalButtons(false);
            setButtonsVisible(false);
            textarea = 'Selected "My Tickets"';
            addDialog(textarea, false, user.name)
            console.log("text area", textarea);
            const usermytickets = "My Tickets";
            const transusermytickets = await translateEnglishToOther(
              usermytickets,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transusermytickets,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);

            const normalmytktmsg = "Please select one of the following options for your tickets"
            const myTicketsMessage = await translateEnglishToOther(
              normalmytktmsg,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: myTicketsMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
            addDialog(normalmytktmsg, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setMyTicketsButtonTranslations(translatedMyTicketsOptions);
            setMyTicketsExtraButtonsVisible(true);
            setAwaitingDescription(false)
            break;

          case "Change Language":

            setShowAdditionalButtons(false);
            console.log("User selected Change language button")
            //await handleSelectLanguage(language)
            const lchange = "Change Language";
            const translchange =
              await translateEnglishToOther(
                lchange,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translchange,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            const changelan = "Please let me know your preferred language."
            const userchangelan = 'Selected "Change Language"'
            addDialog(userchangelan, false, user.name)
            addDialog(changelan, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            const handleChangeLanguage = async () => {
              setLanguage(null);
              console.log("Triggered the handleChangelanguage function")// Reset language to show the language selector again
            };
            await handleChangeLanguage()
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: changelan,
                createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
                from: "bot",
              },
            ]);
            setAwaitingDescription(false);
            break;

          case "Last 5 Tickets":
            setMyTicketsExtraButtonsVisible(false);
            setShowMainMenuExitButtons(false)
            var choice = 'Selected "Last 5 tickets"'
            console.log("After selecting Last 5 Tickets");
            const last5tickets = "Last 5 Tickets";
            const translast5tickets =
              await translateEnglishToOther(
                last5tickets,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translast5tickets,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);

            const messagewait = `Please wait for a moment`;
            const translatedmessagewait = await translateEnglishToOther(
              messagewait,
              language
            );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedmessagewait,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
            try {
              const response = await fetch(
                `${baseUrl}/api/mytickets?mail=${encodeURIComponent(user.username)}`, {
                headers: {
                  "authorization": `Bearer ${token}`,
                }
              }
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              const data = await response.json();
              ticketdata = data;
              addDialog(choice, false, user.name)
              addDialog(ticketdata, true)
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              console.log("Backend API response:", ticketdata);
              var his = await getConversationHistory();
              if (Array.isArray(data) && data.length > 0) {
                const moreTicketMessage = "Please find your recent tickets below. If you would like to see more tickets please"
                const transmoreTicketMessage = await translateEnglishToOther(
                  moreTicketMessage,
                  language
                );
                const hereclick = "click here"
                const transhereclick = await translateEnglishToOther(
                  hereclick,
                  language
                )
                const haloUrl = `${config.Halo_Ticket_URL}/home`;
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: (
                      <span>
                        {transmoreTicketMessage}{" "}
                        <a
                          href={haloUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transhereclick}
                        </a>
                      </span>
                    ),
                    createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
                    from: "bot",
                  },
                ]);
                addDialog(moreTicketMessage, true)
                await getConversationHistory()
                await updateRedis(conversationHistory, newSessionId, token)
                await updatedb();
                setTickets(ticketdata);
              } else {
                setTickets([]);
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.",
                    createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
                    from: "bot",
                  },
                ]);
                setShowMainMenuExitButtons(true);
              }
            } catch (error) {
              console.error("Error fetching tickets:", error);
              setTickets([]);
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: "Couldn't find a ticket for your ID, Please click End Chat to exit the chat or return to the Main Menu.",
                  createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
                  from: "bot" },
              ]);
              setShowMainMenuExitButtons(true);
            }
            setAwaitingDescription(false);
            break;

          case "Search with Ticket Number":
            setMyTicketsExtraButtonsVisible(false);
            setShowMainMenuExitButtons(false)
            const srchtktnum = "Search with Ticket Number";
            const transsrchtktnum =
              await translateEnglishToOther(
                srchtktnum,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transsrchtktnum,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            var txt = 'Selected "Search with ticket number"';
            addDialog(txt, false, user.name)
            agentgenesys = false
            const searchTicketNumberMessage = "Please enter your ticket number";
            const translatedSearchTicketNumberMessage =
              await translateEnglishToOther(
                searchTicketNumberMessage,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: translatedSearchTicketNumberMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
            ]);
            addDialog(searchTicketNumberMessage, true)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setNoKbButtonsVisible(false);
            setButtonsVisible(false);
            setAwaitingDescription(true);
            setPendingOption(option);
            break;

          case "Make a Request":

            setShowAdditionalButtons(false);
            setNoKbButtonsVisible(false)
            setButtonsVisible(false);
            window.open(`${config.Halo_Ticket_URL}/portal/services?btn=95&itil_requesttype_id=3`, "_blank");
            // Replace with actual Halo portal URL
            const requestraise = "Make a Request";
            const transrequestraise =
              await translateEnglishToOther(
                requestraise,
                language
              );
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: transrequestraise,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
            ]);
            addDialog(requestraise, false, user.name)
            await getConversationHistory();
            await updateRedis(conversationHistory, newSessionId, token)
            await updatedb();
            setShowMainMenuExitButtons(true)
            break;

          default:
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
                createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
                from: "bot" },
            ]);
            setAwaitingDescription(false);
            break;
        }
      } catch (error) {
        console.error("Error translating button click message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: `Sorry, there was an error processing your request.  Please close your browser window and start a new chat session`,
            createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
            from: "bot" },
        ]);
      }
    },
    [language, user.username, validateSessionId]
  );

  const handleSubmit = async (e) => {
    console.log("Entered handle submit", textarea)
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    e.preventDefault();
    if (agentgenesys === true) {
      console.log("Inside handle submit in agent genesys if condition")
      await handleagentuser(input);
    }
    else if (textarea === "Report an Issue") {
      console.log("Inside else condition of handle submit")
      if (textarea === "Report an Issue") {
        summary = input;
        console.log("Inside Report an Issue", newSessionId);
        try {
          const response = await fetch(`${baseUrl}/api/awsLex?aa=bb`, {
            method: "POST",
            headers: {
              "authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              summary: summary,
              language: language,
              session: newSessionId
            }),
          });
          setInput('');
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          else {
            const data = await response.json();
            console.log("Data from frontend", data);
            if (data === "FallbackIntent") {
              makerequest = true
              raiseaticketbutton = "Raise a Ticket"
              transraiseaticketbutton = await translateEnglishToOther(
                raiseaticketbutton,
                language
              )
              console.log("Button::::", transraiseaticketbutton)
              console.log("Input entered by user", input)
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: input,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
              ]);
              const translatedMessage3 = await translateOtherToEnglish(
                input,
                language
              );
              addDialog(translatedMessage3, false, user.name)
              setInput("");
              setAwaitingDescription(false);
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              message = "No relevant knowledge article found regarding your query";
              const translatedMessage = await translateEnglishToOther(
                message,
                language
              );
              var cliveagent = "Chat with Live Agent"
              transcliveagent = await translateEnglishToOther(
                cliveagent,
                language
              )
              addDialog(message, true)
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
              ]);
              setNoKbButtonsVisible(true);
              const noKbOptions = ["Raise a Ticket", "Chat with Live Agent", "Make a Request"];
              const translatedNoKbTexts = await translateMultipleEnglishToOther(
                noKbOptions,
                language
              );
            }

            else {
              preSignedURL = data.preSignedURL;
              const validIntents = data.validIntents
              translatedKBArticles = await translateMultipleEnglishToOther(
                validIntents,
                language
              );
              setTranslatedKBArticleButtons(translatedKBArticles);
              console.log("Data from aws s3::", data)
              console.log("Data from aws s3 presigned::", preSignedURL)
              console.log("Data from aws s3 valid intents::", validIntents)
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: input,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
              ]);
              const translatedMessage = await translateOtherToEnglish(
                input,
                language
              );
              console.log("message::", translatedMessage);
              addDialog(translatedMessage, false, user.name)
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token)
              await updatedb();
              setAwaitingDescription(false);
              const noKbOptions = ["Create incident", "Chat with Live Agent"];
              const translatedNoKbTexts = await translateMultipleEnglishToOther(
                noKbOptions,
                language
              );
              raiseaticketbutton = "Raise a Ticket"
              transraiseaticketbutton = await translateEnglishToOther(
                raiseaticketbutton,
                language
              )
              var cliveagent = "Chat with Live Agent"
              transcliveagent = await translateEnglishToOther(
                cliveagent,
                language
              )
              setShowKBarticleButtons(true)
              makerequest = true
              message = "Please review the Knowledge based articles and let me know if you found them helpful?";
              addDialog(message, true)
              await getConversationHistory();
              await updateRedis(conversationHistory, newSessionId, token);
              translatedReviewMessage = await translateEnglishToOther(
                reviewMessage,
                language
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: translatedReviewMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
              ]);
              console.log(translatedReviewMessage);
              const noKbOptions1 = ["Yes", "No"];
              const translatedNoKbTexts1 = await translateMultipleEnglishToOther(
                noKbOptions1,
                language
              );
              setkbYesNoTranslations1(true);
              setkbYesNoTranslations(translatedNoKbTexts1);
              setkblink(true);
              setkbYesNo(true);
              setNoKbButtonsVisible(false);
              setShowAdditionalButtons(false);
              setShowKBarticleButtons(true);
            }
          }
        }

        catch (error) {
          console.error("Error sending message:", error);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
              createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
              from: "bot",
            },
          ]);
        }
      } else if (textarea === "My Tickets") {
        console.log("inside elseif", textarea);
        try {
          await getticketdetails();
        } catch (error) {
          console.error("Error fetching ticket details:", error);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: "Sorry, there was an error processing your request.  Please close your browser window and start a new chat session",
              createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}),
              from: "bot",
            },
          ]);
        }
      }
    }

    else if (agentgenesys === false) {
      console.log('Session ID is validdd');
      console.log("User is in text area", textarea);
      if (input.trim() && language) {
        addDialog(input, false, user.name)
        await getConversationHistory();
        await updateRedis(conversationHistory, newSessionId, token)
        await updatedb();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: input,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
        ]);
        setInput("");
        setAwaitingDescription(true);
        await getticketdetails(input);
      }
    }
  };
  const handleButtonClick45 = async () => {
    console.log("inside handle button click 45", agentgenesys);
    if (agentgenesys == true) {
      await handleagentuser(input);
      console.log("Inside handlebuttonclick45 iff")
    }
    else {
      console.log("Handlebutton click 45 in else condition", agentgenesys)
      await handleSubmit();
    }
  }
  const handleExtraButtonClick = useCallback((extraOption) => {
    if (!validateSessionId(newSessionId)) {
      console.error('Session ID is invalid. Action not allowed.');
      return;
    }
    console.log('Session ID is valid');
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: `You clicked ${extraOption}`,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
    ]);
    setExtraButtonsVisible(false);
  }, [validateSessionId]);

  const handleNoKbButtonClick = useCallback(
    async (action) => {
      if (!validateSessionId(newSessionId)) {
        console.error('Session ID is invalid. Action not allowed.');
        return;
      }
      console.log('Session ID is valid');
      setNoKbButtonsVisible(false);
      setkblink(false);
      setkbYesNo(false);
      message = "Are you reporting the issue for yourself or for others.";
      const translatedMessage = await translateEnglishToOther(
        message,
        language
      );
      addDialog(message, true)
      await getConversationHistory();
      await updateRedis(conversationHistory, newSessionId, token)
      await updatedb();
      const translatedMessageuser = await translateEnglishToOther(
        action,
        language
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: translatedMessageuser,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "user" },
        { text: translatedMessage,createdAt: new Date().toLocaleTimeString([], {timeStyle: 'short'}), from: "bot" },
      ]);
      const createIncident = ["Myself", "Other"];
      const translatedcreateIncidentsVisible =
        await translateMultipleEnglishToOther(createIncident, language);
      setcreateIncidentsVisibleTranslations(translatedcreateIncidentsVisible);
      setcreateIncidentsVisible(true);
      setShowAdditionalButtons(false);
      setAwaitingDescription(false);
    },
    [language, validateSessionId]
  );

  return (
    <div className="chatbot">
      <div className="chat-window">
        <div className="messages">
          {messages.map((msg, index) => {
            return (
              msg.text === reviewMessage || msg.text === translatedReviewMessage) ? (
              <>
                {showKBarticleButtons &&
                  preSignedURL.map((button, buttonIndex) => (
                    <button
                      key={buttonIndex}
                      className="chatbot-button"
                      onClick={() => kbLink(button.url, button.intent)} // Call kburl with both URL and name
                    >
                      {translatedKBArticleButtons[button.intent]} {/* Display button name */}
                    </button>
                  ))}
                <Message
                  key={index}
                  text={msg.text}
                  from={msg.from}
                  createdAt={msg.createdAt}
                />
              </>
            ) : (
              <Message
                key={index}
                text={msg.text}
                from={msg.from}
                createdAt={msg.createdAt}
              />
            );
          })}

          {!language ? (
            <LanguageSelector onSelectLanguage={handleSelectLanguage} />
          ) : (
            <>
              <div>
                {showAdditionalButtons && (
                  <div className="additional-buttons">
                    <button onClick={() => handleButtonClick("My Tickets")}>
                      {translatedTexts["My Tickets"]}
                    </button>
                    <button onClick={() => handleButtonClick("Report an Issue")}>
                      {translatedTexts["Report an Issue"]}
                    </button>
                    <button onClick={() => handleButtonClick("Change Language")}>
                      {translatedTexts["Change Language"]}
                    </button>
                    <button onClick={() => handleButtonClick("Make a Request")}>
                      {translatedTexts["Make a Request"]}
                    </button>
                  </div>
                )}
              </div>
              {extraButtonsVisible && (
                <div className="extra-buttons">
                  <button
                    onClick={() => handleExtraButtonClick("Extra Option 1")}
                  >
                    Extra Option 1
                  </button>
                  <button
                    onClick={() => handleExtraButtonClick("Extra Option 2")}
                  >
                    Extra Option 2
                  </button>
                </div>
              )}

              {raiseRequestButtonVisible && (
                <div>
                  <button
                    variant="contained"
                    onClick={() => handleButtonClick("Make a Request")}
                  >
                    {transrequestraise}
                  </button>
                </div>
              )}

              {myTicketsExtraButtonsVisible && (
                <div className="my-tickets-buttons">
                  <button onClick={() => handleButtonClick("Last 5 Tickets")}>
                    {myTicketsButtonTranslations["Last 5 Tickets"] ||
                      "View Open Tickets"}
                  </button>
                  <button
                    onClick={() => handleButtonClick("Search with Ticket Number")}
                  >
                    {myTicketsButtonTranslations["Search with Ticket Number"] ||
                      "View Closed Tickets"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {noKbButtonsVisible && (
                <div className="no-kb-buttons">
                  <button onClick={() => handleNoKbButtonClick("Raise a Ticket")}>
                    {transraiseaticketbutton}
                  </button>
                  <button onClick={() => handleAgent("Chat with Live Agent")}>
                    {transcliveagent}
                  </button>
                  <button onClick={() => handleButtonClick("Make a Request")}>
                    {transrequestraise}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}
              {createIncidentsVisible && (
                <div className="create-incident">
                  <button onClick={() => handleSelfButton("Myself")}>
                    {createIncidentsVisibleTranslations["Myself"] || "Myself"}
                  </button>
                  <button onClick={() => handleOtherButtonClick("Other")}>
                    {createIncidentsVisibleTranslations["Other"] || "Other"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {showKBhelpList && (
                <div className="kb-yesno">
                  {KBhelpList.map((article, index) => (
                    <button
                      key={index}
                      onClick={() => kbclick(article)}
                    >
                      {article}
                    </button>
                  ))}
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {kbYesNO && (
                <div className="kb-yesno">
                  <button onClick={() => KBhelpListClick("YES")}>
                    {kbYesNOTranslations["Yes"] || "Yes"}
                  </button>
                  <button onClick={() => kbclick("NO")}>
                    {kbYesNOTranslations["No"] || "No"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {kbYesNO1 && (
                <div className="kb-yesno1">
                  <button onClick={() => kbclick1("YES1")}>
                    {kbYesNOTranslations1["Yes"] || "Yes"}
                  </button>
                  <button onClick={() => kbclick1("NO1")}>
                    {kbYesNOTranslations1["No"] || "No"}
                  </button>
                  <button onClick={handleMainMenuClick}>{mainmenu}</button>
                </div>
              )}

              {selfDropdownVisible && (
                <>
                  <div className="dropdown-list">
                    <div
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Organisation Wide")
                          }
                        >
                          Organisation Wide
                        </li>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Multiple Users Affected")
                          }
                        >
                          Multiple Users Affected
                        </li>
                        <li
                          style={{ padding: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDropdownOptionClick("Single Users Affected")
                          }
                        >
                          Single Users Affected
                        </li>
                        <button onClick={handleMainMenuClick}>{mainmenu}</button>
                      </ul>
                    </div>
                  </div>
                  <div className="button-group">
                  </div>
                </>
              )}
            </>
          )}
          {showEmailInput && (
            <div className="chat-message user">
              <div className="chat-bubble">
                <input
                  type="email"
                  placeholder={transmailmsg}
                  value={userEmail}
                  onChange={handleEmailChange}
                  className="email-input"
                />
                <button onClick={handleEmailSubmit} className="submit-button">
                  Submit
                </button>
              </div>
            </div>
          )}

          {buttonmenuafterticketdetails && (
            <div className="additional-buttons">
              <button onClick={handlebacktotickets}>{last5tkt}</button>
              <button onClick={handlesearchwithtktnum}>
                {searchwithtktnumber}
              </button>
              <button onClick={handleShowMoreTicketDetails}>
                {showmoretktdetails}
              </button>
              <button onClick={handleexit}>{exitchat}</button>
              <button onClick={handleMainMenuClick}>{mainmenu}</button>
              <button onClick={() => handleAgent("Chat with Live Agent")}>
                {liveagent}
              </button>
            </div>
          )}
          {menuafterticketdetails && (
            <>
              <TKTCard
                title={menuafterticketdetails.title}
                numbertkt={menuafterticketdetails.numbertkt}
                tktsummary={menuafterticketdetails.tktsummary}
                statetkt={menuafterticketdetails.statetkt}
              />
              <div className="card-buttons">
                <button onClick={handlebacktotickets}>{last5tkt}</button>
                <button onClick={handlesearchwithtktnum}>
                  {searchwithtktnumber}
                </button>
                <button onClick={handleShowMoreTicketDetails}>
                  {showmoretktdetails}
                </button>
                <button onClick={handleexit}>{exitchat}</button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
                <button onClick={() => handleAgent("Chat with Live Agent")}>
                  {liveagent}
                </button>
              </div>
            </>
          )}

          {showSevenOptions && (
            <>
              <div className="dropdown-list">
                <ul>
                  <li
                    onClick={() =>
                      handleSevenOptionClick("Application & Software")
                    }
                  >
                    Application & Software
                  </li>
                  <li onClick={() => handleSevenOptionClick("Hardware")}>
                    Hardware
                  </li>
                  <li onClick={() => handleSevenOptionClick("I Don't Know")}>
                    I Don't Know
                  </li>
                  <li
                    onClick={() => handleSevenOptionClick("IT Security & Risk")}
                  >
                    IT Security & Risk
                  </li>
                  <li onClick={() => handleSevenOptionClick("Microsoft Office")}>
                    Microsoft Office
                  </li>
                  <li onClick={() => handleSevenOptionClick("Network")}>
                    Network
                  </li>
                  <li
                    onClick={() =>
                      handleSevenOptionClick("Reporting & Analytics")
                    }
                  >
                    Reporting & Analytics
                  </li>
                  <li onClick={() => handleSevenOptionClick("User Account")}>
                    User Account
                  </li>
                </ul>
              </div>
              <div className="button-group">
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
              </div>
            </>
          )}
          {showSubcategories && (
            <>
              <div className="dropdown-list subcategory-list">
                <ul>
                  {subcategories.map((subcategory, index) => (
                    <li
                      key={index}
                      onClick={() => handleSubcategoryClick(subcategory)}
                    >
                      {subcategory}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="button-group">
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>

              </div>
            </>
          )}
          {selectedCard && (
            <>
              <Card
                title={selectedCard.title}
                itService={selectedCard.itService}
                requestItem={selectedCard.requestItem}
                summary={selectedCard.summary}
                impactedUser={selectedCard.impactedUser}
              // Conditionally add application name
              />
              <div className="card-buttons">
                <button onClick={() => handleSubmitTicket("Submit")}>
                  {submitbutton}
                </button>
                <button
                  //disabled={true}
                  onClick={() => handleAgent("Chat with Live Agent")}
                >
                  {liveagent}
                </button>
                <button onClick={handleMainMenuClick}>{mainmenu}</button>
              </div>
            </>
          )}
          {backtotickets && (
            <div className="Backt-To-Tickets">
              <button onClick={handlebacktotickets}>{last5tkt}</button>
            </div>
          )}
          {searchwithtktnum && (
            <div className="Search-With-Tkt-Num">
              <button onClick={handlesearchwithtktnum}>
                {searchwithtktnumber}
              </button>
            </div>
          )}
          {showMoreTicketDetailsButton && (
            <div className="Show-More-Ticket-Details">
              <button onClick={handleShowMoreTicketDetails}>
                {showmoretktdetails}
              </button>
            </div>
          )}

          {exitchatbutton && (
            <div className="Exit-Chat">
              <button onClick={handleexit}>{exitchat}</button>
            </div>
          )}
          {chatliveagent && (
            <div className="Chat-Live- Agent">
              <button onClick={handleAgent}>{liveagent}</button>
            </div>
          )}
          {tickets.length > 0 && !showMoreTicketDetailsButton && (
            <div className="ticket-buttons">
              {tickets.map((ticket, index) => (
                <button key={index} onClick={() => handleTicketSelection(ticket)}>
                  {ticket} {/* Display the ticket number */}
                </button>
              ))}
              <button onClick={handleMainMenuClick}>{mainmenu}</button>
            </div>
          )}
          {showMainMenuExitButtons && (
            <div className="button-group">
              <button onClick={() => handleMainMenuClick("Main Menu")}>
                {mainmenu}
              </button>
              <button onClick={handleexit}>{exitchat}</button>
            </div>
          )}
          <div ref={messageEndRef} />
        </div>

        <div className={classes.inputArea}>
          {
            <form onSubmit={handleSubmit} className={classes.inputArea}>
              <TextField
                className={classes.input}
                variant="outlined"
                size="small"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={
                  awaitingDescription
                    ? "Type your description..."
                    : "Type your message..."
                }
                disabled={!awaitingDescription}
              />
              <Button variant="contained" color="primary" type="submit">
                Send
              </Button>
            </form>
          }
        </div>
      </div>
    </div>
  );
}

export default Chatbot;
