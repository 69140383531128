const updateRedis = (conversationHistory, session, token) => {

  return new Promise(async (resolve, reject) => {
    const baseUrl = sessionStorage.getItem('swiss.api.baseUrl');
    const response = await fetch(`${baseUrl}/api/updateRedis`, {
      method: "POST",
      headers: {
        "authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session: session,
        conversationHistory: conversationHistory
      }),
    });
    if (!response.ok) {
      reject({})
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log("Data from frontend", data);
    resolve(data)

  });

};

export default updateRedis;